@font-face {
	font-family: "MuseoSans"; /*300*/
	font-display: swap;
	src: url("../fonts/MuseoSansLight/MuseoSansLight.eot");
	src: url("../fonts/MuseoSansLight/MuseoSansLight.eot?#iefix")format("embedded-opentype"),
		url("../fonts/MuseoSansLight/MuseoSansLight.woff") format("woff"),
		url("../fonts/MuseoSansLight/MuseoSansLight.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "MuseoSans"; /*300*/
	font-display: swap;
	src: url("../fonts/MuseoSansLightItalic/MuseoSansLightItalic.eot");
	src: url("../fonts/MuseoSansLightItalic/MuseoSansLightItalic.eot?#iefix")format("embedded-opentype"),
		url("../fonts/MuseoSansLightItalic/MuseoSansLightItalic.woff") format("woff"),
		url("../fonts/MuseoSansLightItalic/MuseoSansLightItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: "MuseoSans"; /*400*/
	font-display: swap;
	src: url("../fonts/MuseoSansRegular/MuseoSansRegular.eot");
	src: url("../fonts/MuseoSansRegular/MuseoSansRegular.eot?#iefix")format("embedded-opentype"),
		url("../fonts/MuseoSansRegular/MuseoSansRegular.woff") format("woff"),
		url("../fonts/MuseoSansRegular/MuseoSansRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "MuseoSans"; /*900*/
	font-display: swap;
	src: url("../fonts/MuseoSansBlack/MuseoSansBlack.eot");
	src: url("../fonts/MuseoSansBlack/MuseoSansBlack.eot?#iefix")format("embedded-opentype"),
		url("../fonts/MuseoSansBlack/MuseoSansBlack.woff") format("woff"),
		url("../fonts/MuseoSansBlack/MuseoSansBlack.ttf") format("truetype");
	font-style: normal;
	font-weight: 900;
}