/* Libs */
@import 'libs/bootstrap.min';
@import 'libs/slick';
@import 'libs/slick-theme';
@import 'libs/animate.min';

@import 'fonts';
@import 'mixins';

/*--- SPECIFICATION ---*/

body, html{
	max-width: 100%;
	overflow-x: hidden;
}

html {
	font-size: 15px;
}

body {
	font-family: MuseoSans, sans-serif;
	font-weight: 400;
}

* {
	transition: background-color 400ms ease;
}

::selection {background: #7CCB8C;}
::-moz-selection {background: #7CCB8C;}
::-webkit-selection {background: #7CCB8C; color:#fff;}

ul, li {
	display: block;
	padding: 0px;
	margin: 0px;
}

a, a:hover, a:active, a:focus,
button, input, button:hover, button:active, button:focus,
input:focus, input:active {
	text-decoration: none;
	outline: none;
}

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	font-weight: 900;
}

h1 {
	font-size: 6rem;
	letter-spacing: 41.4px;
	margin-right: -41.4px;
}

h2 {
	font-size: 3.4rem;
	letter-spacing: 10.2px;
	margin-right: -10.2px;
}

h3 {
	font-size: 2.8rem;
	letter-spacing: 4.2px;
	margin-right: -4.2px;
	text-align: center;
}

.button {
	text-transform: uppercase;
	@include border-radius(4px);
	border: 2px solid #ffffff;
	font-size: 1rem;
	background: none;
	padding: 20px 30px;
	color: #fff;
	font-weight: 900;
	letter-spacing: 1.5px;
}

.contacts {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;

	.numbers {
		font-size: 1.067rem;
		letter-spacing: 0.32px;
		font-weight: 900;
		margin-left: 15px;
	}
}

/*--- HEADER ---*/

header {
	background: url(../img/bg.png) no-repeat center center / cover;
	height: 100vh;
	color: #fff;
	z-index: 1;
	position: relative;

	.header__overlay {
		@include linear-gradient(-40deg, rgba(0,0,0,0.69) 0%, rgba(0, 80, 113, 0.69) 100%);
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		left: 0; top: 0;
	}

	.header {
		background: rgba(0,0,0,0.25);
	}

	.menu {
		display: flex;
		display: -webkit-flex;
		justify-content: space-around;
		-webkit-justify-content: space-around;

		li a {
			font-size: 0.733rem;
			padding: 14px 12px;
			font-weight: 900;
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1.1px;

			&:hover {
				background: #386175;
				@include border-radius(3px);
			}
		}
	}

	.offer {
		@include text-shadow(0, 0, 46px, rgba(0,0,0,0.31));
		margin-top: 12%;
	}

	.arrow {
		position: absolute;
		left: 50%; bottom: 5%;
		margin-left: -23px;
		z-index: 101;
	}
}

/*--- ADVANTAGES ---*/

#advantages {
	padding: 89px 0 97px 0;
	color: #000;

	h3 {
		margin-bottom: 62px;
	}
	
	.adv__item {
		background-color: #f4f4f4;
		@include box-shadow(1px, 2px, 4px, rgba(149,147,147,0.33));
		padding: 55px 10px 60px 10px;
		height: 357px;
		opacity: 0;
	}

	.adv__img {
		height: 80px;
	}
	
	.adv__img_first {
		background: url(../img/advantages1.png) no-repeat center center;
	}

	.adv__img_second {
		background: url(../img/advantages2.png) no-repeat center center;
	}

	.adv__img_third {
		background: url(../img/advantages3.png) no-repeat center center;
	}

	.adv__heading {
		font-size: 1.333rem;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-right: -2px;
		font-weight: 900;
		margin-top: 28px;
	}

	.adv__heading:after {
		content: '';
		display: block;
		width: 37px;
		height: 1px;
		background: rgba(0,0,0,0.19);
		margin: 17px auto;
	}

	.adv__description {
		font-size: 1.133rem;
		opacity: 0.8;
	}
}

/*--- RELOCATION ---*/

.relocation {
	background: url(../img/bg-relocation.png) no-repeat center center / cover;
	position: relative;
	z-index: 1;
	padding: 95px 0 99px 0;
	color: #fff;

	.relocation__overlay {
		position: absolute;
		left: 0; top: 0;
		width: 100%;
		height: 100%;
		background: rgba(14, 42, 65, 0.86);
		z-index: -1;
	}

	.relocation__quote {
		margin-top: 29px;
		font-size: 1.2rem;
		font-style: italic;
		font-weight: 300;
	}

	.relocation__author {
		display: block;
		font-size: 0.933rem;
		font-style: normal;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 2.52px;
		margin-right: -2.52px;
		margin-top: 17px;
	}	

	.relocation__how {
		border: 1px dashed #fff;
		position: relative;
		font-size: 1.267rem;
		text-transform: uppercase;
		letter-spacing: 1.9px;
		font-weight: 300;
		padding: 59px 0;
		margin: 57px 0 71px 0;
	}

	.relocation__arrow {
		position: absolute;
		left: 50%;
		margin-left: -16px;
	}

	.relocation__arrow_top {
		top: 0;
		margin-top: -16px;
	}

	.relocation__arrow_bottom {
		bottom: 0;
		margin-bottom: -16px;
	}

	.relocation__bolder {
		font-size: 1.533rem;
		font-weight: 900;
		letter-spacing: 2.3px;
		margin-right: -2.3px;
	}

	.relocation__description {
		font-size: 1.2rem;
		letter-spacing: 1.8px;
		font-weight: 300;
	}

	.relocation__button {
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
		margin-top: 48px;

		button:hover {
			border: 2px solid grey;
		}
	}
}

/*--- SERVICES ---*/

.services {
	background: url(../img/city.png) no-repeat center top / cover;
	color: #000;
	padding: 108px 0 98px 0;

	.dream {
		@include box-shadow(1px, 2px, 4px, rgba(149,147,147,0.33));
		position: relative;
		margin-top: 150px;
	}

	.dream__img {
		position: absolute;
		top: 0;
		z-index: 6;
		margin-top: -70px;
	}

	.dream__img_left {
		left: 42%;
		margin-left: -60px;
	}

	.dream__img_middle {
		left: 50%;
		margin-left: -94px;
		margin-top: -120px;
		z-index: 8;
	}

	.dream__img_right {
		right: 40%;
		margin-right: -60px;
	}

	.dream__text {
		z-index: 7;
		padding: 73px 59px 43px 59px;
		position: relative;
		background: #f4f4f4;
	}

	.dream__circle {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -31px;
		margin-bottom: -35px;
	}

	h4 {
		font-size: 1.33rem;
		line-height: 25px;
		text-align: center;
		letter-spacing: 1.5px;
	}

	.description {
		font-size: 1.133rem;
		font-weight: 300;
		line-height: 31px;
		text-align: center;
	}

	.ready {
		background: #f4f4f4;
		margin-top: 6px;
		@include box-shadow(1px, 2px, 4px, rgba(149,147,147,0.33));
		padding: 40px 20px;
	}

	.services__button {
		text-align: center;
		margin-top: 60px;

		button {
			border: 2px solid #23aae2;
			color: #23aae2;
		}
	}
}

/*--- REVIEWS ---*/

.reviews {
	padding: 89px 0 90px 0;

	.arrow {
		font-size: 3rem;
		color: #23aae2;
		letter-spacing: 4.5px;
		margin-right: -4.5px;
		margin-top: 67px;

		&:hover {
			cursor: pointer;
		}
	}

	.review {
		display: flex;
		display: -webkit-flex;
		@include box-shadow(1px, 2px, 4px, rgba(149,147,147,0.33));
		margin-top: 67px;
	}

	.review__item {
		position: relative;
		overflow: hidden;
	}

	.review__avatar {
		position: absolute;
		left: 0; top: 50%;
		margin-left: -69px;
		margin-top: -69px;
	}

	.review__description {
		display: flex;
		display: -webkit-flex;
		flex-flow: column nowrap;
		-webkit-flex-flow: column nowrap;
		padding-left: 127px;
		padding-right: 41px;
		padding: 46px 41px 47px 127px;
	}

	.review__info {
		display: flex;
		display: -webkit-flex;
		flex-flow: row nowrap;
		-webkit-flex-flow: row nowrap;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		text-transform: uppercase;
		color: #23aae2;
		font-size: 0.8rem;
		letter-spacing: 1.2px;
	}

	.review__name {
		font-size: 1.067rem;
		letter-spacing: 1.6px;
		margin-right: -1.6px;
		font-weight: 900;
		color: #000;
	}

	.review__link {
		text-decoration: underline;
		font-weight: 900;
	}

	.review__direction {
		font-weight: 300;
	}

	.review__text {
		font-style: italic;
		font-size: 0.933rem;
		letter-spacing: 1.4px;
		line-height: 20px;
		margin-top: 27px;
		color: #363636;
		font-weight: 300;
	}

	.my-dots {
	  position: absolute;
	  bottom: -45px;
	  display: block;
	  width: 100%;
	  padding: 0;
	  list-style: none;
	  text-align: center;
	}

	.my-dots li {
	  position: relative;
	  display: inline-block;
	  width: 20px;
	  height: 20px;
	  margin: 0 5px;
	  padding: 0;
	  cursor: pointer;
	}

	.my-dots li button {
	  font-size: 0;
	  line-height: 0;
	  display: block;
	  width: 20px;
	  height: 20px;
	  padding: 5px;
	  cursor: pointer;
	  color: transparent;
	  border: 0;
	  outline: none;
	  background: transparent;
	}

	.my-dots li button:hover,
	.my-dots li button:focus {
	  outline: none;
	}

	.my-dots li button:hover:before,
	.my-dots li button:focus:before {
	  opacity: 0.75;
	}

	.my-dots li button:before {
	  font-family: 'slick';
	  font-size: 6px;
	  line-height: 20px;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 20px;
	  height: 20px;
	  content: url('../img/dots_active.png');
	  text-align: center;
	  opacity: .25;
	  color: black;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	   
	}

	.my-dots li.slick-active button:before {
	  opacity: 1;
	  color: black;
	}
}

/*--- CONSULTANT ---*/

#consult {
	background: #11b2f5;
	color: #fff;
	position: relative;
	padding: 100px 0 75px 0;
	z-index: 1;

	.consult__overlay {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background: url(../img/bg_consult.png) no-repeat center center / cover;
	}

	.free-consult {
		font-size: 1.4rem;
		letter-spacing: 2.1px;
		margin-right: -2.1px;
		font-weight: 300;
		text-transform: uppercase;
		margin-bottom: 5%;
	}
}


/*--- Стили форм ---*/

form {
	display: flex;
	display: -webkit-flex;
	flex-flow: column nowrap;
	-webkit-flex-flow: column nowrap;

	.input {
		@include border-radius(3px);
		border: 1px solid #ffffff;
		background-color: #30c2ff;
		padding: 22px 0 22px 36px;
		margin-bottom: 10px;
	}

	.input[type="email"], .input[type="text"], .input[type="tel"] {
		font-size: 1.067rem;
		color: #fff;
	}

	.input:focus {
		@include box-shadow(0, 0, 35px, rgba(14,14,14,0.3));
	}

	::placeholder {
		font-size: 0.87rem;
		text-transform: uppercase;
		letter-spacing: 1.3px;
		color: #fff;
		color: rgba(255,255,255,0.56);
	}

	.error {
    	border: 1px solid #e34a39;
    }

    label.error {
    	border: none;
    }
}

/*--- FOOTER ---*/

footer {
	display: flex;
	display: -webkit-flex;
	background-color: #23aae2;
	@include box-shadow(3px, -13px, 131px, rgba(0,0,0,0.26));
	font-size: 0.933rem;

	.all_rights {
		color: #faf7f7;
		opacity: 0.76;
	}

	.privacy_policy {
		a {
			color: rgba(255, 255, 255, .76);
			border-bottom: 1px solid rgba(255, 255, 255, .76);
		}
	}

	.contacts {
		color: #fff;
	}
}



/*--- ABOUT ---*/

.about {
	background-position: center 25%;
	position: relative;
	height: 60vh;
	z-index: 0;

	.overlay {
		z-index: -1;
	}

	.offer {
		// position: absolute;
		// top: 0; left: 0;
		margin-top: 4%;
	}

	.arrow {
		bottom: 28%;
	}
}

.about_company {
	background: #f7f7f7;
	padding-bottom: 65px;

	.about_company__description {
		margin-top: -80px;
		background: #fff;
		padding: 50px 0 74px 0;
		z-index: 1;
	}

	.about__term {
		padding: 0 75px;
		font-size: 1.2rem;
		line-height: 25px;
		font-weight: 300;

		.bolder {
			font-weight: 900;
		}
	}

	.about__facts {
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		align-items: center;
		-webkit-align-items: center;
		text-transform: uppercase;
		margin: 70px 0 58px 0;
	}

	.about__satisfied {
		font-size: 2.6rem;
		color: #23aae2;
		letter-spacing: 3.9px;
		margin-right: -3.9px;
		line-height: 33px;
		font-weight: 900;

		.smaller {
			letter-spacing: 1px;
			font-size: 1rem;
			color: #000;
		}
	}

	.about__found {
		font-size: 1.267rem;
		letter-spacing: 6.9px;
		margin-right: -6.9px;
		color: #bdbdbd;
		font-weight: 300;
	}

	.about__home {
		font-size: 2rem;
		color: #23aae2;
		letter-spacing: 2.8px;
		font-weight: 900;
		line-height: 32.15px;

		.blacker {
			letter-spacing: 3.76px;
			color: #000;
		}
	}

	.about_company__ready {
		background-color: #23aae2;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
		text-transform: uppercase;
		letter-spacing: 2.76px;
		font-size: 1.533rem;
		padding: 30px 0;
		font-weight: 900;
		color: #fff;
	}

	.about_company__item {
		background-color: #f4f4f4;
		margin-top: 50px;
		padding: 40px 35px;
	}

	.about_company__heading {
		margin-top: 65px;
		font-size: 1.93rem;
		font-weight: 900;
		letter-spacing: 2.9px;
		text-transform: uppercase;
		color: #000;
		opacity: 0.99;
	}

	.about_company__names {
		font-size: 1.4rem;
		letter-spacing: 1.68px;
		font-weight: 900;
		color: #000;
		margin-top: 29px;
	}

	.about_company__names:after {
		content: '';
		display: block;
		height: 2px;
		width: 45px;
		background: rgba(0,0,0,0.19);
		margin: 17px auto;
	}

	.about_company__text {
		font-size: 1.067rem;
		letter-spacing: 0.32px;
		line-height: 23px;
		color: rgba(0, 0, 0, 0.8);
		font-weight: 300;
		text-align: justify;
		height: 300px;
	}
}


/*--- DEVELOPED ---*/

.developed {
	background: url(../img/bg_developed.png) no-repeat center center / cover;
	height: 100vh;
	position: relative;
	z-index: 1;

	h2 {
		margin-top: 24%;
	}
}


#menu-bar {
	z-index: 200;
	position: relative;
}



#menu-header {
	height: 40vh;
	width: 50%;
	text-align: center;
	position: absolute;
	top: 77px; right: 0;
	z-index: 100;
	@include border-radius(5px);
	background: #23aae2;
	color: #fff;
	display: none;
	
	ul {
		display: flex;
		display: -webkit-flex;
		height: 100%;
		align-items: center;
		// -webkit-align-items: stretch;
		justify-content: space-around;
		-webkit-justify-content: space-around	;
		flex-flow: column nowrap;
		-webkit-flex-flow: column nowrap;
	}

	li {
		font-size: 1.25rem;
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	li a {
		color: #fff;
	}
}

@import 'pageup';
@import 'modal';
@import 'menu';