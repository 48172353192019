/* Text shadow */
@mixin text-shadow($horizOffset, $vertOffset, $blur, $color) {
    -webkit-text-shadow: $horizOffset $vertOffset $blur $color;
    -moz-text-shadow: $horizOffset $vertOffset $blur $color;
    -ms-text-shadow: $horizOffset $vertOffset $blur $color; /* IE 10+ */
    -o-text-shadow: $horizOffset $vertOffset $blur $color;
    text-shadow: $horizOffset $vertOffset $blur $color;
}

/* Box shadow */
@mixin box-shadow($horizOffset, $vertOffset, $blur, $color) {
    -webkit-box-shadow: $horizOffset $vertOffset $blur $color;
    -moz-box-shadow: $horizOffset $vertOffset $blur $color;
    -ms-box-shadow: $horizOffset $vertOffset $blur $color;
    -o-box-shadow: $horizOffset $vertOffset $blur $color;
    box-shadow: $horizOffset $vertOffset $blur $color;
}

/* Border-radius */
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

/* Gradient */
@mixin linear-gradient($direction, $fromColor, $toColor) {
    background-color: $toColor; /* Fallback Color */
    background-image: -webkit-gradient(linear, $direction, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient($direction, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image:    -moz-linear-gradient($direction, $fromColor, $toColor); /* FF3.6 */
    background-image:     -ms-linear-gradient($direction, $fromColor, $toColor); /* IE10 */
    background-image:      -o-linear-gradient($direction, $fromColor, $toColor);/* Opera 11.10+ */
    background-image:         linear-gradient($direction, $fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}