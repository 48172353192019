.pageup {
	font-size: 4rem;
	display: none;
	color: #156188;
	position: fixed;
	z-index: 10;
	bottom: 20px; right: 40px;

	i {
		width: 100%;
	}
}