.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	background: rgba(0, 0, 0, .56);
	z-index: 100;
	display: none;
}

.modal-consult {
	background: #23aae2;
	width: 480px;
	min-height: 450px;
	position: absolute;
	left: 50%; top: 50%;
	transform: translate(-50%, -50%);
	padding: 50px 40px;
    box-shadow: 0 0 0 6px rgba(153, 153, 153, .3);
    border-radius: 10px;
    color: #fff;

    .overlay__close {
		position: absolute;
		top: 0;
		right: -40px;
		line-height: 1.8rem;
		font-size: 2.8rem;
		color: #23aae2;
		cursor: pointer;
    }

    .overlay__subtitle {
		font-size: 1.5rem;
		font-weight: 900;
    }

    .overlay__description {
    	margin-bottom: 15px;
    }
}

.mini {
	min-height: 100px !important; 
}

#consultation, #thanks {
	display: none;
}