.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#fff;
  stroke-width:5.5;
  stroke-linecap:round;
}
.ham .top {
  stroke-dasharray: 40 121;
}
.ham .bottom {
  stroke-dasharray: 40 121;
}
.ham.active .top {
  stroke-dashoffset: -68px;
}
.ham.active .bottom {
  stroke-dashoffset: -68px;
}